/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <input
                    v-model="vmClubFormData.club_name"
                    :placeholder="cvClubNameLabel"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    v-model="vmClubFormData.club_desc"
                    :placeholder="cvClubDescLabel"
                    type="text"
                    class="form-control"
                    rows="2"
                    max-rows="6"                  />
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="clubAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Clubs } from "../../../FackApi/api/club"
import ApiResponse from "../../../Utils/apiResponse.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "ClubAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "club_add",
      cvCardTitle: "Add club",
      cvCardSubHeader: "Add club ",
      cvSubmitBtn: "Add",
      cvClubNameLabel: "Name",
      cvClubDescLabel: "About",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Club",
      vmClubFormData: Object.assign({}, this.initFormData()),
      vmClubDesc: null,
      vmClubType: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "club_name": "",
        "club_desc": "",
        "created_by": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmClubFormData) {
          if (!this.vmClubFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * clubAdd
     */
    async clubAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let clubAddResp = await Clubs.clubAdd(this, this.vmClubFormData)
        await ApiResponse.responseMessageDisplay(this, clubAddResp)
        if (clubAddResp && !clubAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseClubAddModal", clubAddResp.resp_data)
        }
        this.vmClubFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at clubAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
